import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import loginBanner from "../../../assets/img/loginbanner.jpg";
import axios from "axios";
import * as Constants from "../../../Constants/index";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./loginStyle.css";
import whitearrow from "../../../assets/img/whitearrow.svg";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

export default function Login() {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [acceptTncPp, setAcceptTncPp] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    if (userType === "SALES") {
      navigate("/dashboardSales");
    } else if (userType === "RETAILER") {
      navigate("/dashboard_retailer");
    } else if (userType === "FA") {
      navigate("/redemptionApproval");
    } else if (userType === "HO") {
      navigate("/dashboardAdmin");
    } else if (userType === "FA") {
      navigate("/dashboardFinance");
    }
  }, []);

  const toggleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };
  const toogleTerms = () => {
    if (acceptTncPp === false) {
      console.log(acceptTncPp);
      setAcceptTncPp(true);
    } else {
      console.log(acceptTncPp);
      setAcceptTncPp(false);
    }
  };
  const validateForm = () => {
    let errors = {};

    if (!mobile) {
      errors.mobile = "User ID is required";
    } else if (!/^\d+$/.test(mobile)) {
      errors.mobile = "User ID must be numeric";
    } else if (mobile.length !== 10) {
      errors.mobile = "User ID must be 10 digits";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    if (!acceptTncPp) errors.tncpp = "Please accept the terms and conditions.";

    return errors;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // handleLogin();
    }
  };

  const LoginButton = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleLogin = async () => {
      setErrors({});
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return; // Don't proceed with the API call if there are validation errors
      }

      const token = await executeRecaptcha("loginAction");

      try {
        await axios
          .post(
            Constants.baseUrl + "auth/login-password",
            {
              mobile: mobile,
              password: password,
              fcmToken: "",
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
                score: token,
              },
            }
          )
          .then((res) => {
            const dt = res.data.output;
            if (res.status === 200) {
              if (res.data.code === 10001) {
                if (res.data.output.user.userType === "SALES") {
                  localStorage.setItem("token", dt?.access?.token);
                  localStorage.setItem("user_id", dt?.user?.id);
                  localStorage.setItem("user_type", dt?.user?.userType);
                  localStorage.setItem("user_name", dt?.user?.name);
                  localStorage.setItem("user_email", dt?.user?.email);
                  localStorage.setItem("user_mobile", dt?.user?.mobile);
                  localStorage.setItem("userUniq_id", dt?.user?.userUniqid);
                  navigate("/dashboardsales");
                } else if (dt?.user.userType === "RETAILER") {
                  localStorage.setItem("token", dt?.access?.token);
                  localStorage.setItem("user_id", dt?.user.id);
                  localStorage.setItem("user_type", dt?.user.userType);
                  localStorage.setItem("user_name", dt?.user.name);
                  localStorage.setItem("user_email", dt?.user.email);
                  localStorage.setItem("user_mobile", dt?.user.mobile);
                  localStorage.setItem("userUniq_id", dt?.user.userUniqid);
                  localStorage.setItem("gst", dt?.user.gst);
                  navigate("/dashboard_retailer");
                } else if (dt?.user.userType === "HO") {
                  localStorage.setItem("token", dt?.access?.token);
                  localStorage.setItem("user_id", dt?.user?.id);
                  localStorage.setItem("user_type", dt?.user?.userType);
                  localStorage.setItem("user_name", dt?.user?.name);
                  localStorage.setItem("user_email", dt?.user?.email);
                  localStorage.setItem("user_mobile", dt?.user?.mobile);
                  localStorage.setItem("userUniq_id", dt?.user?.userUniqid);
                  localStorage.setItem("gst", dt?.user?.gst);
                  navigate("/dashboardAdmin");
                } else if (dt?.user.userType === "FA") {
                  localStorage.setItem("token", dt?.access?.token);
                  localStorage.setItem("user_id", dt?.user?.id);
                  localStorage.setItem("user_type", dt?.user?.userType);
                  localStorage.setItem("user_name", dt?.user?.name);
                  localStorage.setItem("user_email", dt?.user?.email);
                  localStorage.setItem("user_mobile", dt?.user?.mobile);
                  localStorage.setItem("userUniq_id", dt?.user?.userUniqid);
                  localStorage.setItem("gst", dt?.user?.gst);
                  navigate("/dashboardFinance");
                } else {
                  swal({
                    text: "User role not found. Please contact customer care.",
                    icon: "error",
                    buttons: false,
                    timer: 2000,
                  });
                }
              } else if (res.data.code === 16001) {
                swal({
                  text: res.data.msg,
                  buttons: false,
                  timer: 2000,
                });
              } else {
                swal({
                  text: res.data.msg,
                  buttons: false,
                  timer: 2000,
                });
              }
            } else {
              swal({
                text: res.data.msg,
                buttons: false,
                timer: 2000,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <button type="submit" className="btn loginbuttn" onClick={handleLogin}>
        LOG IN <img className="logarrow" src={whitearrow} alt="" />
      </button>
    );
  };
  return (
    <>
      <Header />
      <section
        className="loginsec mt134 bglogin"
        style={{ backgroundImage: `url(${loginBanner})` }}
      >
        <div className="container">
          <div className="loginwindow">
            <div className="row mr0">
              <div className="col-md-8 col-md-offset-2 col-xs-12 loginside">
                <div className="formlogin">
                  <h2>Log In</h2>
                  <div className="form-group form-group-mb">
                    <label htmlFor="user id">User ID</label>
                    <input
                      type="text"
                      value={mobile}
                      maxLength="10"
                      onChange={(event) => {
                        const input = event.target.value;
                        if (/^\d*$/.test(input)) {
                          setMobile(input);
                        }
                      }}
                      placeholder="User ID"
                      className="form-control frm"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  {errors.mobile && (
                    <div className="error-message-login">{errors.mobile}</div>
                  )}
                  <div className="form-group form-group-mb">
                    <label htmlFor="review">Password</label>

                    <input
                      type={showPassword === true ? "text" : "password"}
                      id="myInput"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder="Enter Password"
                      className="form-control frm"
                      onKeyDown={handleKeyDown}
                    />
                    <span
                      className="showpass"
                      title="Show Password"
                      onClick={toggleShowPassword}
                    >
                      {showPassword === false ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      )}
                    </span>
                  </div>
                  {errors.password && (
                    <div className="error-message-login">{errors.password}</div>
                  )}

                                    <div className='text-justify form-group form-group-mb 'style={{display: 'flex', columnGap: "5px", alignItems: "start", justifyContent: "start"}}> 
                                        <input type='checkbox' onChange={toogleTerms} required />
                                        <p style={{ fontSize: 8, lineHeight: 1 }} className='text-dark text-truncate'>
                                            To enhance your experience and ensure we provide the best service possible, we need your consent to process your personal data.
                                            By ticking the box, you agree to our Privacy Policy and Terms of Service. Your data will be used in accordance with these documents to manage your account and
                                            deliver personalized content. Please review our policies before proceeding.
                                            I confirm that I have read, consent and agree to Finolex Super Plumber Loyalty Program's <a href='/app/tnc_en' target='blank'><u>Terms & Conditions</u> </a>and <a href='/app/pp_en' target='blank'><u>Privacy Policy</u></a>.
                                        </p>
                                    </div>
                                    {errors.tncpp && (
                                        <div className="error-message-login">{errors.tncpp}</div>
                                    )}

                  <div className="form-group mtop20">
                    <GoogleReCaptchaProvider
                      reCaptchaKey={Constants.REACT_APP_RECAPTCHA_KEY}
                    >
                      <LoginButton />
                    </GoogleReCaptchaProvider>
                  </div>
                  <div className="forgtPassword">
                    <Link to="/forgot_password">Forgot password ?</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
