import React, { useState } from "react";
import "./style.css";

export default function Kannada() {
  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          backgroundColor: "#ffffff",
          padding: "20px",
          fontSize: "0.75rem",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            1. Introduction
          </h4>
          <p>
            This Privacy Notice is intended to describe the practices at Finolex
            Industries Limited (hereinafter referred to as FIL/Finolex) follows
            in relation to our Plumber Loyalty Application with respect to the
            privacy of all individuals whose personal data is collected,
            processed and stored. This Privacy Notice should be read together
            with the data privacy policy, and in case of any conflict with the
            policy, the terms of this Privacy Notice will prevail. Please read
            this Privacy Notice carefully.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            2. Who manages the Plumber Loyalty Application?
          </h4>
          <p>
            “Finolex Pipes and Fittings” is a separate legal entity and can
            determine the purposes and means for data processing (i.e., act as a
            data controller or in a similar capacity). The entity that is acting
            as data controller (or similar capacity) by providing this
            application on which your personal data will be processed and stored
            is:
          </p>
          <ul>
            <li>
              For the personal data of Finolex personnel: The data fiduciary is
              Finolex, the entity which employs you.
            </li>
            <li>
              For the personal data of third-party personnel (including Finolex
              clients): The data controller is the Finolex local member firm
              with which the third party has a relationship.
            </li>
          </ul>
          <p>
            The personal data collected by the application may be shared by the
            above data controller with one or more member firms.
          </p>
          <p>Our Commitment -</p>
          <ul>
            <li>
              Your information will not be shared, rented, or sold to any
              third-party service provider/s.
            </li>
            <li>
              We use state-of-the-art data security measures to protect your
              information from unauthorized users.
            </li>
          </ul>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            3. How does the Plumber Loyalty Application process personal data?
          </h4>
          <p>
            Processing of your personal data is necessary for the purposes of
            the legitimate business purposes/ interests pursued by the data
            fiduciary or by a third party, except where such interests are
            overridden by the interests or fundamental rights and freedoms of
            the data subject which require protection of personal data.
          </p>
          <p>
            You have the right to object at any time, on grounds relating to
            your particular situation, to the processing of personal data.
          </p>
          <p>
            Acceptance of our Terms and Conditions and this Privacy Policy will
            be deemed as the customer’s consent to the processing of data of the
            end-user/employee on behalf of the customer/organization for all
            purposes detailed in this document.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            4. What type of personal data is processed on the Plumber Loyalty
            Application?
          </h4>
          <p>
            The Plumber Loyalty Application processes these personal data
            categories:
          </p>
          <ul>
            <li>Name of the individual</li>
            <li>Phone number</li>
            <li>Gender</li>
            <li>Bank details</li>
            <li>GST number</li>
            <li>PAN card number</li>
            <li>Aadhar card details</li>
            <li>Complete address of that individual – State, city, pincode</li>
          </ul>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            5. Who can access your personal data?
          </h4>
          <p>
            Your personal data may be processed by Finolex and its affiliates
            for the purposes outlined in this Privacy Notice. Additionally,
            selected third-party service providers acting on our behalf may
            process your personal data to fulfil the specified purposes, under
            strict confidentiality and in compliance with applicable data
            protection laws. These third parties may include, but are not
            limited to, cloud service providers, payment processors, and
            analytics services. We ensure that all third-party processors adhere
            to appropriate data protection standards and implement adequate
            security measures to safeguard your personal data.
          </p>
          <p>
            To the extent that personal data has been rendered anonymous in such
            a way that you or your device are no longer reasonably identifiable,
            such information will be treated as non-personal data and the terms
            of this Privacy Notice will not apply.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            6. Data retention
          </h4>
          <p>
            Our policy is to retain personal data only for as long as it is
            needed for the purposes described in the section “How does the
            plumber loyalty process personal data.” Retention periods vary in
            different jurisdictions and are set in accordance with local
            regulatory and professional retention requirements. In order to meet
            our professional and legal requirements, to establish, exercise or
            defend our legal rights and for archiving and historical purposes,
            we need to retain information for significant periods of time. The
            policies and/or procedures for the retention of personal data in the
            application are:
          </p>
          <p>
            The total retention period is defined and will be implemented in
            accordance with the PLA data retention policy and applicable data
            protection laws. Log Data will be retained in accordance with the IT
            Logging Policy and CERT-IN guidelines. After the end of the data
            retention period, your personal data will be deleted.
          </p>
          <p>
            We shall, unless retention is necessary for compliance with any law
            for the time being in force, erase personal data if you withdraw
            consent as soon as it is reasonable to assume that the specified
            purpose of such data is no longer necessary. However, if necessary,
            as per policy we may retain your information for our compliance with
            a legal, accounting, or reporting obligation or for archiving
            purposes as per the requirement of any applicable law in force
            and/or the Act.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>7. Security</h4>
          <p>
            The application protects the confidentiality and security of
            information it obtains in the course of its business. Access to such
            information is limited, and policies and procedures are in place
            that are designed to safeguard the information from loss, misuse,
            and improper disclosure. However, please be aware that in spite of
            us putting in reasonable efforts to protect your personal
            information, and regardless of any security measures we use, we
            cannot and do not guarantee the absolute protection and security of
            any personal data stored with us.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            8. Cookies & Tracking Technologies
          </h4>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our application and we hold certain information. Cookies
            are files with a small amount of data which may include an anonymous
            unique identifier. Cookies are processed on the application and
            stored on your device.
          </p>
          <p>
            You can customize your cookie preferences to refuse all cookies or
            to indicate when a cookie is being sent. However, if you do not
            accept cookies, you may not be able to use some portions of our
            Service.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            9. Controlling your personal data
          </h4>
          <p>
            The application will not transfer your personal data to third
            parties (other than any external parties referred to in section 6
            above) unless we have your consent or are required by law to do so.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            10. Your rights in relation to your personal data
          </h4>
          <p>
            Depending on the applicable jurisdiction, you may have certain
            rights in relation to your personal data, including:
          </p>
          <ul>
            <li>
              To request details of the personal data FIL processes about you
              and to access the personal data that the application processes
              about you (“right to access”)
            </li>
            <li>
              To have your personal data corrected, for example, if it is
              incomplete or incorrect (“right to correction”)
            </li>
            <li>
              To restrict or object to the processing of personal data or
              request the erasure of your personal data (“right to object”)
            </li>
            <li>
              To receive a copy of the personal data which you have provided to
              Finolex in a structured, commonly used and machine-readable format
              which you can re-use for your own purposes (“right to data
              portability”)
            </li>
            <li>
              Where you have provided consent to the processing of your personal
              data, the right to withdraw your consent. (“right to opt-out”)
            </li>
            <li>
              Where you can nominate an individual to exercise the
              above-mentioned data subject rights in case of death or incapacity
              (“right to nominate”)
            </li>
            <li>
              The right to complain to a data protection authority (see section
              “Complaints”)
            </li>
          </ul>
          <p>
            If you have any questions about how Plumber Loyalty Application
            processes your personal data or your rights related to your personal
            data, please send an e-mail to{" "}
            <a href="mailto:krd@finolexind.com">krd@finolexind.com</a>
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>11. Complaints</h4>
          <p>
            If you are concerned about an alleged breach of privacy law or any
            other regulation, contact:
          </p>
          <p>Name: Mr. Krishna Dayma</p>
          <p>Designation: Deputy Manager- Legal and Secretarial</p>
          <p>
            Email ID: <a href="mailto:krd@finolexind.com">krd@finolexind.com</a>
          </p>
          <p>
            If you are not satisfied with how Plumber Loyalty Application
            resolved your complaint, you may have the right to complain to the
            data protection board. You may also have the right to refer the
            matter to a court of competent jurisdiction.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>
            12. Additional Notices
          </h4>
          <p>
            We may update and amend this privacy notice and the terms and
            conditions from time to time by posting an amended version on our
            application. The amended version will be effective as of the date it
            is published. When we make material changes to this privacy notice,
            we will provide users with notice as appropriate under the
            circumstances, e.g., by displaying a prominent notice on the
            application or by sending an email.
          </p>
        </div>
        <div className="section" style={{ marginBottom: "20px" }}>
          <h4 style={{ color: "#007bff", fontSize: "1rem" }}>13. Contact us</h4>
          <p>
            If you have additional questions or concerns, contact our Finolex
            representative at{" "}
            <a href="mailto:krd@finolexind.com">krd@finolexind.com</a>
          </p>
        </div>
      </div>

      <div
        className="footer"
        style={{
          textAlign: "center",
          marginTop: "20px",
          padding: "20px",
          backgroundColor: "#007bff",
          color: "white",
        }}
      >
        <p>&copy; 2024 Finolex Industries Limited. All rights reserved.</p>
      </div>
    </>
  );
}
