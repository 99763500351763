import React, { useState } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';

export default function EnrollmentReport() {
    const navigate = useNavigate()
    const userType = localStorage.getItem('user_type')
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null)
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10)
    const [filterData, setFilterData] = useState({})

    const searchFormik = useFormik({
        initialValues: {
            status: '',
            startDate: '',
            endDate: '',
            mobile: ''
        },
        validate: (data) => {
            let errors = {}
            if (data.startDate === '' && data.endDate !== '') {
                errors.startDate = 'Please select date'
            }
            if (data.startDate !== '' && data.endDate === '') {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'End date should be greater than start date'
            }
            // if (data.startDate && data.endDate) {
            //     const maxEndDate = new Date(data.startDate)
            //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

            //     if (data.endDate > maxEndDate) {
            //         errors.endDate = 'End date should be within 31 days from the start date.'
            //     }
            // }
            return errors
        },
        onSubmit: (data) => {
            setCurrentPage(1)
            getReportData(data, 1)
            setFilterData(data)
        },
    })
    const isSearchFormFieldValid = (name) => !!(searchFormik.touched[name] && searchFormik.errors[name])
    const getSearchFormErrorMessage = (name) => {
        return isSearchFormFieldValid(name) && <small className="report-error-field">{searchFormik.errors[name]}</small>
    }



    const getReportData = async (data, pageNum) => {
        setLoading(true);
        if (!token) {
            navigate('/')
        } else {
            const searchStatus = data.status === '' ? '' : `&status=${data.status}`
            let stDate
            let endDate
            let mobile

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `&start=${startFormattedDate}`
                endDate = `&end=${startFormattedDate4}`
                mobile = `&mobile=${data.mobile}`
            } else {
                stDate = ''
                endDate = ''
                mobile = ''
            }

            await axios.get(Constants.baseUrl + `reports/users/list?userType=MEMBER${mobile}${searchStatus}${stDate}${endDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            }).then((res) => {
                const dt = res.data
                if (dt.result === 'success') {
                    console.log('103', dt.output.results);
                    setReportData(dt?.output?.results)
                    setTotalPages(dt?.output?.totalPages)
                    setLoading(false)
                } if (dt.result === 'error' || dt.result === 'dialog') {
                    setReportData(null)
                    setTotalPages(0)
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false)
            })
        }
    }

    const download = (data) => {
        setLoading(true)
        try {
            const searchStatus = data.status === '' ? '' : `&status=${data.status}`
            let stDate
            let endDate

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `&start=${startFormattedDate}`
                endDate = `&end=${startFormattedDate4}`
            } else {
                stDate = ''
                endDate = ''
            }


            axios.get(Constants.baseUrl + `reports/download/userreport?userType=MEMBER${searchStatus}${stDate}${endDate}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'
            }).then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    const fileData = new Blob([res.data], { type: 'text/csv' });
                    saveAs(fileData, 'enrollmentReport.csv');
                } else {
                    swal({
                        text: res.data.msg,
                        // icon: 'error',
                        timer: 2000,
                        buttons: false,
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const areAllValuesBlank = () => {
        const { mobile, status, startDate, endDate } = searchFormik.values
        return (
           !mobile && !status && !startDate && !endDate
        )
    }

    const onReset = () => {
        setCurrentPage(1);
        searchFormik.resetForm();
        getReportData({ mobile: "", status: "", startDate: "", endDate: "" }, 1);
      };

    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Enrollment Report  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <form onSubmit={searchFormik.handleSubmit} onReset={onReset}>
                                    <div className="row clearfix mb-15 callwell">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <input
                                                id="mobile"
                                                value={searchFormik.values.mobile}
                                                onChange={(e) => {
                                                    // Allow only numeric input
                                                    const numericValue = e.target.value.replace(
                                                        /\D/g,
                                                        ""
                                                    );
                                                    searchFormik.handleChange({
                                                        target: {
                                                            name: "mobile",
                                                            value: numericValue,
                                                        },
                                                    });
                                                }}
                                                type="text"
                                                className="frmcntrl"
                                                placeholder="Plumber Mobile Number"
                                                maxLength="10"
                                            />
                                            {getSearchFormErrorMessage("mobile")}
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="startDate"
                                                selected={searchFormik.values.startDate}
                                                onChange={(date) => searchFormik.setFieldValue('startDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='From date'
                                            />
                                            {getSearchFormErrorMessage('startDate')}
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="endDate"
                                                selected={searchFormik.values.endDate}
                                                onChange={(date) => searchFormik.setFieldValue('endDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='To date'
                                            />
                                            {getSearchFormErrorMessage('endDate')}
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <select className="frmcntrl" id='status' value={searchFormik.values.status} onChange={searchFormik.handleChange}>
                                                <option value=''>Select Status</option>
                                                <option value="APPROVED">Approved</option>
                                                <option value="REJECT">Rejected</option>
                                                {/* <option value="PENDING">Pending for approval</option> */}
                                                <option value="NEW">New Plumber</option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-4" style={{ marginTop: "10px" }}>
                                            <button class="btn login_white_btn" type="submit" disabled={areAllValuesBlank()}> Search <img src={longarrow} alt='' /></button>
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-4" style={{marginTop: "10px"}}>{/* col-md-1 col-sm-1 col-xs-1 col-md-offset-5 */}
                                            <button className="btn login_white_btn" type="reset" disabled={areAllValuesBlank()}>
                                                Reset
                                            </button>
                                        </div> 
                                    </div>
                                </form>

                                <div className="row">
                                    <div className="col-md-12">
                                        {reportData === null || userType === 'RETAILER' || userType === 'SALES' ? '' :
                                            <button class="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" type='submit' onClick={() => download(searchFormik.values)}
                                            > Export</button>}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01 " >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Plumber Unique ID<br/> (Mobile Number)</th>
                                                            <th>Plumber Name</th>                                                           
                                                            {userType === 'HO' ? <th>Account<br/> Status</th> : ''}
                                                            {userType === 'HO' ? <th>Deleted</th> : ''}
                                                            <th>Alternate Mobile</th>
                                                            <th>WhatsApp Number</th>
                                                            <th>Mobile Number Linked with Aadhar</th>
                                                            <th>Gender</th>
                                                            <th>DOB</th>
                                                            <th>City</th>
                                                            <th>District</th>
                                                            <th>State</th>
                                                            <th>Pin Code</th>
                                                            <th>Aadhar Number</th>
                                                            {/* {userType === 'HO' ? <th>Aadhaar Front Image</th> : ''} */}
                                                            {/* {userType === 'HO' ? <th>Aadhaar Back Image</th> : ''} */}
                                                            {/* {userType === 'HO' ? <th>Mobile No. linked with Aadhaar</th> : ''} */}
                                                            {userType === 'HO' ? <th>PAN Number</th> : ''}
                                                            {userType === 'HO' ? <th>PAN Image Status<br/> (Uploaded Yes/No)</th> : ''}
                                                            {userType === 'HO' ? <th>Bank Account No.</th> : ''}
                                                            {userType === 'HO' ? <th>Account Holder's Name</th> : ''}
                                                            {userType === 'HO' ? <th>Account Type</th> : ''}
                                                            {userType === 'HO' ? <th>Bank Name</th> : ''}
                                                            {userType === 'HO' ? <th>IFSC Code</th> : ''}
                                                            {userType === 'HO' ? <th>Branch</th> : ''}
                                                            {userType === 'HO' ? <th>UPI ID</th> : ''}
                                                            {userType === 'HO' ? <th>UPI ID Image Status</th> : ''}
                                                            {/* {userType === 'HO' ? <th>PayTm Number</th> : ''} */}
                                                            {/* {userType === 'HO' ? <th>Paytm Image Status</th> : ''} */}
                                                            <th>Date of Registration</th>
                                                            <th>Mode of Registration</th>
                                                            <th>KYC Status</th>
                                                            {userType === 'HO' ? <th>Approved/Rejected By</th> : ''}
                                                            {userType === 'HO' ? <th>Approved/Rejected Date</th> : ''}
                                                            {userType === 'HO' ? <th>Rejected Reason</th> : ''}
                                                            {/* {userType === 'HO' ? <th>Tier/Level</th> : ''} */}
                                                           
                                                            {userType === 'HO' ? <th>Profile Status</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Code</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Name</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Mobile No.</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer City</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer District</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer Pin Code</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer State</th> : ''}
                                                            {userType === 'HO' ? <th>Retailer GST No</th> : ''}

                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>

                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>

                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>

                                                            {/* <th>Zone</th> */}
                                                            <th>ZSM Name</th>
                                                            <th>ZSM Mobile No.</th>
                                                            {/* <th>Zone State</th> */}
                                                            {/* <th>Zone Area Name</th> */}
                                                            
                                                           
                                                            
                                                            
                                                            
                                                        </tr>
                                                    </thead>
                                                    {reportData === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {reportData && reportData.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const dateOfReg = item[20] ? moment(item[20]).format('DD-MM-YYYY') : ''
                                                                const dob = item[25] ? moment(item[25]).format('DD-MM-YYYY') : ''
                                                                const isAadharFrontUrlAvailable = item[26] && item[26].trim() !== "";
                                                                const isAadharBackUrlAvailable = item[27] && item[27].trim() !== "";
                                                                const actionDate = item[43] ? moment(item[43]).format('DD-MM-YYYY') : ''

                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item[15]}</td>
                                                                            <td>{item[14]}</td>                                                                            
                                                                            {userType === 'HO' ? <td>{item[39] === true ? 'Unblocked' : 'Blocked'}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[49] === true ? 'Yes' : 'No'}</td> : ''}
                                                                            <td>{item[22]}</td>
                                                                            <td>{item[23]}</td>
                                                                            <td>{item[44]}</td>
                                                                            <td>{item[24]}</td>
                                                                            <td>{dob}</td>
                                                                            <td>{item[16]}</td>
                                                                            <td>{item[17]}</td>
                                                                            <td>{item[18]}</td>
                                                                            <td>{item[19]}</td>
                                                                            <td>{item[50] == null ? "N/A" : item[50]}</td>
                                                                            {/* {userType === 'HO' ? <td>{isAadharFrontUrlAvailable ? (
                                                                                <Link to={item[26]} target="_blank" className="select">View</Link>) : (
                                                                                "N/A"
                                                                            )}</td> : ''}
                                                                            {userType === 'HO' ? <td>{isAadharBackUrlAvailable ? (
                                                                                <Link to={item[27]} target="_blank" className="select">View</Link>) : (
                                                                                "N/A"
                                                                            )}</td> : ''} */}
                                                                            {/* {userType === 'HO' ? <td></td> : ''} */}
                                                                            {userType === 'HO' ? <td>{item[28]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[29] === '' ? 'NO' : 'YES'}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[30]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[31]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[32]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[33]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[34]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[35]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[36]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[38] === '' ? 'NO' : 'YES'}</td> : ''}
                                                                            {/* {userType === 'HO' ? <td>{item[37]}</td> : ''} */}
                                                                            {/* {userType === 'HO' ? <td></td> : ''} */}
                                                                            <td>{dateOfReg}</td>
                                                                            <td>{item[38]}</td>
                                                                            <td>{item[21]}</td>
                                                                            {userType === 'HO' ? <td>{item[41]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{actionDate}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[42]}</td> : ''}
                                                                            {/* {userType === 'HO' ? <td></td> : ''} */}
                                                                            
                                                                            {userType === 'HO' ? <td>{item[45]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[6]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[7]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[8]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[11]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[12]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[13]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[10]}</td> : ''}
                                                                            {userType === 'HO' ? <td>{item[9]}</td> : ''}

                                                                            <td>{item[4]}</td>
                                                                            <td>{item[5]}</td>

                                                                            <td>{item[2]}</td>
                                                                            <td>{item[3]}</td>

                                                                            <td>{item[0]}</td>
                                                                            <td>{item[1]}</td>

                                                                            {/* <td></td> */}
                                                                            <td>{item[47]}</td>
                                                                            <td>{item[48]}</td>
                                                                            {/* <td></td>
                                                                            <td></td> */}
                                                                            
                                                                            
                                                                            
                                                                            
                                                                            

                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => {
                                                            setCurrentPage(page);
                                                            getReportData(filterData, page)
                                                        }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}
